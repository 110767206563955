import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Table } from "react-bootstrap"
import { Card } from "reactstrap"
import { get, debounce } from "lodash"
import AddUserModal from "components/Pages/User/addUserModal"
import EditUserModal from "components/Pages/User/EditUserModal"
import ChangePasswordUserModal from "components/Pages/User/ChangePasswordUserModal"
import {
  useGetUsersQuery,
  useGetRolesQuery,
  useGetUserByIdMutation,
  useDeleteUserMutation,
} from "store/user/user.api"
import LoadingSpinner from "components/Reports/EditReports/EditTables/loader"
import { setUsers, setRoles, setCurrentUser } from "store/user/user.slice"
import Swal from "sweetalert2"
import ReactPaginate from "react-paginate"
import Ink from "react-ink"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "../../components/Common/Breadcrumb"

const User = () => {
  const dispatch = useDispatch()
  const [isAddUserModalOpen, setAddUserModalOpen] = useState(false)
  const [isEditUserModalOpen, setEditUserModalOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [searchText, setSearchText] = useState("")
  // const [selectedUser, setSelectedUser] = useState(null)
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState(false)
  const [selectedUserForPasswordChange, setSelectedUserForPasswordChange] =
    useState(null)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(1)
  const [rowCount, setRowCount] = useState(0)
  const {
    data: user_data,
    isFetching,
    isLoading,
    isError,
    refetch: refetchUsers,
  } = useGetUsersQuery(
    {
      page: page,
      pageSize: pageSize,
      searchTerm: searchText,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  )
  const { users, selectedUser, roles } = useSelector(state => state.usersNew)
  const { data: role_data } = useGetRolesQuery()
  const [
    getUser,
    { data: user, isFetching: isUserFetching, isLoading: isUserLoading },
  ] = useGetUserByIdMutation()
  const [deleteUser, { isLoading: deleteLoading }] = useDeleteUserMutation()

  useEffect(() => {
    if (user_data) {
      let response = get(user_data, "data", [])
      let userTemp = get(response, "rows", [])
      let count = get(response, "count", 1)
      setRowCount(count)
      let pages = get(response, "totalPages", 1)
      setTotalPages(pages)
      dispatch(setUsers(userTemp))
      // setData(user_data)
    }
  }, [user_data])

  useEffect(() => {
    if (isError) {
      console.log(isError)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      })
    }
  }, [isError])

  useEffect(() => {
    if (role_data) {
      let response = get(role_data, "data", [])
      dispatch(
        setRoles(
          response.map(role => {
            return {
              id: role.id,
              label: role.label,
              value: role.label,
            }
          })
        )
      )
    }
  }, [role_data])

  const clearUser = () => {
    dispatch(setCurrentUser({}))
  }

  const openEditUserModal = user => {
    setEditUserModalOpen(true)
    getUser(user)
      .unwrap()
      .then(data => {
        let user_temp = get(data, "data", [])
        dispatch(setCurrentUser(user_temp))
      })
      .catch(error => {
        console.log(error)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        })
        setEditUserModalOpen(false)
      })
  }

  const openChangePasswordModal = user => {
    setSelectedUserForPasswordChange(user)
    setChangePasswordModalOpen(true)
  }

  const handleDeleteUser = userId => {
    if (!userId) {
      return
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#811113",
        cancelButtonColor: "#7066e0",
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.isConfirmed) {
          deleteUser({ user_id: userId })
            .unwrap()
            .then(data => {
              Swal.fire("Deleted!", "The user has been deleted.", "success")
              setPage(1)
              refetchUsers()
            })
            .catch(error => {
              console.log(error)
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              })
            })
        }
      })
    }
  }
  // const formatDateTime = (isoString) => {
  //   if(isoString){
  //     const date = new Date(isoString);
  //     const year = date.getFullYear();
  //     const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  //     const day = String(date.getDate()).padStart(2, '0');
  //     const hours = String(date.getHours()).padStart(2, '0');
  //     const minutes = String(date.getMinutes()).padStart(2, '0');
  //     const seconds = String(date.getSeconds()).padStart(2, '0');
      
  //     return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  //   }
  // };

  const formatDateTime = (isoString) => {
    if (isoString) {
      const date = new Date(isoString);
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      };
      const formattedDate = date.toLocaleString('en-GB', options).replace(',', '');
      const [datePart, timePart] = formattedDate.split(' ');
      const [day, month, year] = datePart.split('/');
      return `${year}-${month}-${day} ${timePart}`;
    }
    return '';
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Users</title>
      </MetaTags>
      {/* <Breadcrumbs title="Settings" breadcrumbItem="Users " /> */}
      <div className="ps-2 user-page">
        <div className="mb-4 user-breadcrumb">
          {/* <span style={{ fontSize: "34px" }}>Users</span> */}
          <Breadcrumbs title="Settings" breadcrumbItem="Users" />
        </div>
        <Card style={{ borderRadius: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "18px",
              margin: "15px",
              marginTop: "24px",
            }}
          >
            <div
              className="me-3"
              style={{
                border: "1px solid #D9D9D9",
                borderRadius: "50px",
                marginBottom: "10px",
                minWidth: "210px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <i
                class="bx bx-search-alt search-icon sm-left-30 ps-2 pe-2 "
                style={{ marginTop: "2px" }}
              ></i>
              <input
                type="text"
                placeholder="Search"
                style={{
                  width: "150px",
                  height: "31px",
                  border: "none",
                  borderRadius: "10px",
                }}
                value={searchTerm}
                onChange={e => {
                  setSearchTerm(e.target.value)
                }}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    setPage(1)
                    setSearchText(searchTerm)
                  }
                }}
              />
              {searchTerm !== "" && (
                // <i className="bx bx-search-alt search-icon sm-left-30" />
                <i
                  className="fas fa-times me-2 ms-2"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setPage(1)
                    setSearchTerm("")
                    setSearchText("")
                  }}
                ></i>
              )}
            </div>
            <div>
              <button
                className="btn btn-primary"
                style={{
                  backgroundColor: "#811113",
                  border: "none",
                  width: "100px",
                  height: "42",
                  marginBottom: "10px",
                }}
                onClick={() => setAddUserModalOpen(true)}
              >
                Add User
              </button>
            </div>
          </div>
          <div style={{ marginRight: "30px"}}>
            <div style={{
              overflowX: "auto",
            }}>
            <Table
              className="ms-3"
              style={{
                borderRadius: "20px",
                justifyContent: "center",
                alignItems: "center",
                width: "100%", // Set the table width to 100%
              }}
            >
              <thead>
                <tr style={{ fontSize: "13px" }}>
                  <th
                    style={{
                      width: "8%",
                      textAlign: "left",
                      paddingLeft: "56px",
                    }}
                  >
                    ID
                  </th>
                  <th style={{ width: "15%", textAlign: "left" }}>Name</th>
                  <th style={{ width: "20%", textAlign: "left" }}>Email</th>
                  <th
                    className="role"
                    style={{
                      width: "5%",
                      textAlign: "left",
                      paddingLeft: "100px",
                    }}
                  >
                    Role
                  </th>
                  <th
                    className="login-status"
                    style={{
                      width: "16%",
                      textAlign: "left",
                      paddingLeft: "100px",
                    }}
                  >
                    Login Status
                  </th>
                  <th
                    className="last-activity"
                    style={{
                      width: "16%",
                      textAlign: "left",
                      paddingLeft: "100px",
                    }}
                  >
                    Last Activity
                  </th>
                  <th
                    className="action"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      paddingLeft: "1px",
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              {isLoading || isFetching ? (
                <tr>
                  <td></td>
                  <td></td>
                  <td
                    style={{
                      width: "20%",
                    }}
                  >
                    <LoadingSpinner />
                  </td>
                </tr>
              ) : users.length > 0 ? (
                <tbody style={{ fontSize: "13px" }}>
                  {users.map(user => (
                    <tr key={user.id}>
                      <td
                        style={{
                          width: "8%",
                          textAlign: "left",
                          paddingLeft: "56px",
                        }}
                      >
                        {user.id}
                      </td>
                      <td style={{ width: "15%", textAlign: "left" }}>
                        {user.name}
                      </td>
                      <td style={{ width: "20%", textAlign: "left" }}>
                        {user.email}
                      </td>
                      <td
                        className="role"
                        style={{
                          width: "5%",
                          textAlign: "left",
                          paddingLeft: "100px",
                        }}
                      >
                        {user.user_role.label}
                      </td>
                      <td
                        className="login-status"
                        style={{
                          width: "20%",
                          textAlign: "left",
                          minWidth: "200px",
                          paddingLeft: "100px",
                        }}
                      >
                        {user.login_status || "-"}
                      </td>
                      <td
                        className="last-activity"
                        style={{
                          width: "12%",
                          textAlign: "left",
                          minWidth: "240px",
                          paddingLeft: "100px",
                        }}
                      >
                        {formatDateTime(user.last_activity) || "-"}
                      </td>
                      <td
                        className="action-data"
                        style={{
                          width: "20%",
                          minWidth: "220px",
                          textAlign: "center",
                          paddingLeft: "0px",
                        }}
                      >
                        <button
                          style={{
                            color: "#F47673",
                            border: "none",
                            background: "none",
                          }}
                          onClick={() => handleDeleteUser(user.id)}
                        >
                          <i class="fas fa-times"></i>
                        </button>

                        <button
                          style={{
                            color: "#4E5157",
                            border: "none",
                            background: "none",
                            paddingLeft: "10px",
                          }}
                          onClick={() => openEditUserModal(user.id)}
                        >
                          <i class="fas fa-pen"></i>
                        </button>

                        <button
                          style={{
                            color: "#CFA88B",
                            border: "none",
                            background: "none",
                            paddingLeft: "10px",
                          }}
                          onClick={() => openChangePasswordModal(user.id)}
                        >
                          <i class="fas fa-key"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tr>
                  <td colSpan="5" style={{ textAlign: "center", paddingTop:'20px' }}>No Data Found</td>
                </tr>
              )}
            </Table>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                alignItems: "right",
                margin: "0px 20px 10px 0px",
              }}
            >
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={totalPages}
                forcePage={page-1}
                renderOnZeroPageCount={null}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={data => {
                  setPage(data.selected + 1)
                }}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </Card>
        <AddUserModal
          isOpen={isAddUserModalOpen}
          roles={roles}
          refetch={refetchUsers}
          onClose={() => setAddUserModalOpen(false)}
        />
        <EditUserModal
          isOpen={isEditUserModalOpen}
          onClose={() => setEditUserModalOpen(false)}
          userData={selectedUser}
          roles={roles}
          isLoading={isUserLoading || isUserFetching}
          refetch={refetchUsers}
          clearUser={clearUser}
        />
        <ChangePasswordUserModal
          isOpen={isChangePasswordModalOpen}
          onClose={() => setChangePasswordModalOpen(false)}
          userData={selectedUserForPasswordChange}
        />
      </div>
    </React.Fragment>
  )
}

export default User
