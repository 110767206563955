import React, { Component, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody, Container, Button,Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter} from "reactstrap"
import Select from "react-select"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import moment from "moment"
import exchangeCurrencies from "constants/currencies"
import LoadingSpinnerUpload from "./LoadingSpinner_upload"
import FilteredReports from "./GenerateReport/FilteredReports"
import { getCustomers, getPartners, getTemplates, getOperators, searchUploadedBatches,searchBatchSuccess,setSearchedBatches} from "store/old/source-upload/actions"
import { getNewCustomers } from "store/old/customer/actions"
import { getNewOperators } from "store/old/operator/actions"
import toastr from "toastr";
import Swal from "sweetalert2"
import "toastr/build/toastr.min.css";
import store from "../../store/index";
import { useDispatch, useSelector } from "react-redux"
import { generateReport, setSelectedReportToGenerate, setGenerating } from "store/old/reports/actions"
import { set } from "store"

const { exchangeRates, currencies } = require("exchange-rates-api")

export default function ReportGenerate() {
  const [date, setDate] = useState("")
  const [foreignExchangeDate, setForeignExchangeDate] = useState("")
  const [selectedCurrency, setSelectCurrency] = useState("")
  const [currencyRate, setCurrencyRate] = useState("")
  const [errorExchange, setErrorExchange] = useState("")
  const dispatch = useDispatch();
  const [selectedTemplate, setTemplate] = useState("")
  const [selectedOperator, setOperator] = useState("")
  const [selectedCustomer, setCustomer] = useState("")
  const [selectedDate, setTestDate] = useState("")
  const [sizePerPage, setSizePerPage] = useState(5)
  const [modal_pipe_upload, setmodal_pipe_upload] = useState(false)
  let selectedRows = useSelector(state => state.Reports.selectedReportToGenerate);


  const searchedBatches_test = useSelector((state) => state.SourceUpload.searchedBatches);
  // console.log("searchedBatches_test", searchedBatches_test);
  const searchedBatches = searchedBatches_test.map((obj) => {
    return {
      id: obj.batch.id,
      batch_name: obj.batch.batch_name,
      createdAt: obj.batch.createdAt,
      customer: obj.customers_test.name,
      operator: obj.operator_test.operator_name+"  "+obj.operator_test.country,
      username: obj.batch.users_new?.name,
    }
  })
  // console.log(searchedBatches);
  useEffect(() => {

    dispatch(getNewCustomers())
    dispatch(getNewOperators())
    dispatch(getPartners())
    dispatch(getTemplates())

  }, [])
  const customertest = useSelector((state)=>state.customer.customers);
  const customer = customertest.map(obj=>{
    return {id:obj.id,label:obj.name,value:obj.name}
  })
  const operatortest = useSelector((state)=>state.operator.operators);
  const operator = operatortest.map(obj=>{
    return {id:obj.id,
      label:obj.country+" "+obj.operator_name, 
      value:obj.country+" "+obj.operator_name}
  })
  operator.sort((a, b) => {
    const countryA = a.label.split(' ')[0];
    const countryB = b.label.split(' ')[0];
  
    // Compare country names
    if (countryA < countryB) {
      return -1;
    } else if (countryA > countryB) {
      return 1;
    } else {
      return 0;
    }
  });
  const template = useSelector((state) => state.SourceUpload.templates);
  const partners = useSelector((state) => state.SourceUpload.partners);

  useEffect(() => {
    searchBatches();
  }, [selectedCustomer, selectedOperator, selectedDate, selectedTemplate, sizePerPage])




  const searchBatches = () => {
    dispatch(searchBatchSuccess(false))
    dispatch(setSearchedBatches([]))
    dispatch(searchUploadedBatches({
      customer: selectedCustomer,
      operator: selectedOperator,
      testDate: selectedDate,
      template: selectedTemplate,
      size: sizePerPage
    }
    ))
  }

  const currency = []

  for (var key in exchangeCurrencies) {
    let label = key
    let value = exchangeCurrencies[key]

    currency.push({
      label: label,
      value: value,
    })
  }
  const getExchangeRate = async () => {
    if (selectedCurrency != "" && foreignExchangeDate != "") {
      try {
        let resp = await exchangeRates()
          .setApiBaseUrl("https://api.exchangerate.host")
          .at(foreignExchangeDate)
          .base(currencies.USD)
          .symbols(selectedCurrency)
          .fetch()
        setCurrencyRate(resp)
        setErrorExchange("")
      } catch (error) {
        setCurrencyRate("")
        setErrorExchange("Sorry, Please enter valid data")
      }
    }
  }

  useEffect(() => {
    var now = new Date()
    var dateString = moment(now).format("YYYY-MM-DD")
    setDate(dateString)
    setForeignExchangeDate(dateString)
  }, [])

  useEffect(() => {
    getExchangeRate()
  }, [selectedCurrency, foreignExchangeDate])



  const genReport = async () => {
    // console.log(selectedRows.length)
    if (selectedRows.length > 1) {
      setmodal_pipe_upload(true)
      dispatch(setGenerating(true));
      for (let i = 0; i < selectedRows.length; i++) {
        const element = selectedRows[i];
        // console.log(element.id)
        dispatch(generateReport(
          {
            batch_id: element.id,
            date: date,
            // currency_type: selectedCurrency

          }))
      }

    } else if (selectedRows.length == 1) {
      // console.log(seletedBatches)
      setmodal_pipe_upload(true)
      dispatch(setGenerating(true));
      dispatch(generateReport(
        {
          "batch_id": selectedRows[0].id,
          "date": date,
          // "currency_type": selectedCurrency

        }
      ))
      setmodal_pipe_upload(true)
      let uploadPromise = new Promise(async(resolve,reject)=>{
        store.subscribe(()=>{
          const deleteSuccess = store.getState();
          const status = deleteSuccess.Reports.generating
          if(status == false){
            // console.log(status);
            resolve("Done")
          }
        })
      })
      await uploadPromise.then(async(msg)=>{
        // Toast.fire({
        //   icon: 'success',
        //   title: 'Delete Success!'
        // })
        setmodal_pipe_upload(false);
        dispatch(setSelectedReportToGenerate([]))
        // console.log("Upload Success");
        Swal.fire('Report Generation Completed.', '', 'success')
        // Toast.fire({
        //   icon: 'success',
        //   title: 'File Deleted'
        // })
      })
    }


  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Report Generate</title>
        </MetaTags>
            <div className="container-fluid">
            <Breadcrumbs title="Generate Reports" breadcrumbItem="Reports " />
  
            <Row>
              <Col className="col-12">
                <Card className="bg-white">
                  <CardBody>
                    <Row className="mb-2">
                      <Col md="12" sm="12">
                        <Row
                          style={{zIndex:"2", position:"relative"}}
                        >
                          <Col md="2">
                            <h5 className="col-form-label fs-5">Filters</h5>
                          </Col>
                          <Col md="2">
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isSearchable={true}
                              name="customer"
                              options={[
                                { value: '', label: 'No Customer Selected' },
                                ...customer
                              ]}
                              placeholder="Customer"
                              // onChange={(e) => {
                              //   setCustomer(e.id)
                              // }}
                              onChange={e => {
                                // console.log(e.id);
                                if(e.id){
                                setCustomer(e.id)
                                setOperator('')
                                dispatch(getNewOperators({
                                  customer_id: e.id
                                }))
                                }
                                if(e.value === ''){
                                setCustomer('')
                                setOperator('')
                                dispatch(getNewOperators())
                                }
                              }}
                            />
                          </Col>
                          <Col md="2">
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isSearchable={true}
                              name="operator"
                              options={[
                                { value: '', label: 'No Operator Selected' },
                                ...operator
                              ]}
                              placeholder="Operator"
                              value={operator.filter(obj => obj.id === selectedOperator)}
                              onChange={e => {
                                if(e.id){
                                setOperator(e.id)
                                }
                                if(e.value === ''){
                                setOperator('')
                                }
                              }}
                            />
                          </Col>
                          {/* <Col md="2" style={{
                              paddingTop: "5px"
                            }}>
                            <input
                              className="form-control"
                              type="date"
                              id="testDate"
                              value={selectedDate}
                              onChange={e => setTestDate(e.target.value)}
                              style={{ height: "38px" }}
  
                            />
                          </Col> */}
                            <Col md="3" style={{
                              paddingTop: "5px",
                              display: "flex",
                            }}>
                              <Col md={4} style={{display:"flex"}}>
                              <p style={{
                                marginTop : 'auto',
                                marginBottom : 'auto',
                              }}>
                                Uploaded Date : 
                              </p>
                              </Col>
                              <Col md={8}>
                              <input
                                className="form-control"
                                type="date"
                                id="testDate"
                                placeholder="Test Date"
                                style={{ height: "38px" }}
                                onChange={e => {
                                  setTestDate(e.target.value)
                                }}
                              />
                              </Col>
                            </Col>
                          <Col md="2">
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isSearchable={true}
                              name="template"
                              options={[
                                { value: '', label: 'No Template Selected' },
                                ...template
                              ]}
                              placeholder="Template"
                              onChange={e => {
                                if(e.id){
                                setTemplate(e.id)
                                }
                                if(e.value === ''){
                                setTemplate('')
                                }
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
  
                    </Row>
                    <Col md="12" sm="12">
                      <FilteredReports searchedBatches={searchedBatches} loadRows={setSizePerPage} />
                    </Col>
  
                    {/* <Row className="mb-2" style={{ paddingBottom: "20px" }}>
                      <Col md="12" sm="12">
                        <Row>
                          <Col md="2">
                            <label className="col-form-label">
                              FX Rate as of
                            </label>
                          </Col>
                          <Col md="3">
                            <input
                              className="form-control"
                              type="date"
                              id="exchangeRateDate"
                              value={foreignExchangeDate}
                              onChange={e => {
                                setErrorExchange("")
                                setForeignExchangeDate(e.target.value)
                              }}
                              style={{ height: "38px" }}
                            />
                          </Col>
                          <Col md="2">
                            <label className="col-form-label">
                              Currency Type
                            </label>
                          </Col>
                          <Col md="3">
                            <div>
                              <Select
                                className="basic-single zindex-dropdown"
                                classNamePrefix="select"
                                isSearchable={true}
                                name="currency-type"
                                options={currency}
                                placeholder="Select Currency Type"
                                onChange={e => {
                                  setErrorExchange("")
                                  setSelectCurrency(e.value)
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
  
                      {currencyRate != "" ? (
                        <p>
                          {currencyRate} +{selectedCurrency} +
                          {foreignExchangeDate}
                        </p>
                      ) : errorExchange != "" ? (
                        <p>{errorExchange}</p>
                      ) : null}
                    </Row> */}
                    <Row>
                      <Col md="12" className="text-end">
                        <Button
                          disabled={selectedCurrency == "" ? false : false}
                          className="btn btn-custom md-position-absolute ms-auto p-2 bd-highlight"
                          onClick={()=>{
                            genReport()
                          }}
                        >
                          Generate Report
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
  
            {/* <ReportTable /> */}
          </div>
      </div>
      <Modal isOpen={modal_pipe_upload}>
        <ModalHeader>Source Upload</ModalHeader>
        <ModalBody>
          <LoadingSpinnerUpload/>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </Modal> 
    </React.Fragment>
  )
}
