import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Button, Table } from "reactstrap"
import Select from "react-select"
import axios from "axios"
import * as url from "../../../helpers/url_helper"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { getTemplates } from "store/old/source-upload/actions"
import {
  getReports,
  searchReports,
  setSelectedReportID,
  getReportsSuccess,
  deleteReport,
  setdeleteReportSuccess,
  setReports,
  downloadReport,
  setReportsGetSuccess,
  setSelectedReport,
} from "store/old/reports/actions"
import { getNewCustomers } from "store/old/customer/actions"
import { getNewOperators } from "store/old/operator/actions"
import { Link } from "react-router-dom"
import Swal from "sweetalert2"
import "toastr/build/toastr.min.css"
import store from "../../../store/index"
import LoadingSpinnerUpload from "./LoadingSpinner_upload"
import ReactPaginate from "react-paginate"
import LoadingSpinner from "./EditTables/loader"
import jwt from "jsonwebtoken"
import MetaTags from "react-meta-tags"
import { useShareReportMutation } from "store/reports/reports.api"
import { set } from "store"

const FilteredReports = props => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    didOpen: toast => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })
  const dispatch = useDispatch()
  let setLoading = props.setLoading
  const [currentPage, setCurrentPage] = useState(0)
  const itemsPerPage = 5
  const offset = currentPage * itemsPerPage
  const scrollFunc = props.clickFunction
  const [
    shareReport,
    {
      isLoading: shareReportLoading,
      isError: reportShareError,
      isSuccess: reportshareSuccess,
    },
  ] = useShareReportMutation()
  const decodeToken = token => {
    try {
      const decodedToken = jwt.decode(token)
      return decodedToken
    } catch (error) {
      console.log("Error decoding token:", error)
      return null
    }
  }

  const token = localStorage.getItem("authUser")
  const decodedToken = decodeToken(JSON.parse(token))
  let role = decodedToken.role

  // const selectedReports = useSelector(state => state.Reports.selectedReport)
  const reportsGet_success = useSelector(
    state => state.Reports.getReportsSuccess
  )
  const reports = useSelector(state => state.Reports.reports)
  const currentPageItems = reports?.slice(offset, offset + itemsPerPage)

  useEffect(() => {
    dispatch(getNewCustomers())
    dispatch(getNewOperators())
    dispatch(getTemplates())
  }, [])
  const customertest = useSelector(state => state.customer.customers)
  const customer = customertest.map(obj => {
    return { id: obj.id, label: obj.name, value: obj.name }
  })
  const operatortest = useSelector(state => state.operator.operators)
  const operator = operatortest.map(obj => {
    return {
      id: obj.id,
      label: obj.operator_name + " " + obj.country,
      value: obj.operator_name + " " + obj.country,
    }
  })
  const template = useSelector(state => state.SourceUpload.templates)

  const [selectedCustomer, setCustomer] = useState("")
  const [isDeleting, setIsDeleting] = useState(false)
  const [deletingID, setDeletingID] = useState("")
  const [selectedOperator, setOperator] = useState("")
  const [selectedTemplate, setTemplate] = useState("")
  const [selectedHist, setSelectedHist] = useState("gnt")
  const [refetchedReports, setRefetchedReports] = useState(true)
  const [modal_pipe_upload, setModal_pipe_upload] = useState(false)

  const [sizePerPage, setSizePerPage] = useState(10)

  // Table Data
  const historicalData = [
    {
      id: 2,
      label: "Historical Reports",
      value: "hst",
    },
    {
      id: 2,
      label: "Generated Reports",
      value: "gnt",
    },
    {
      id: 1,
      label: "All Reports",
      value: "all",
    },
  ]
  async function deleteProj(id) {
    Swal.fire({
      title: "Do you want really want to Delete the Report",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Delete",
      denyButtonText: `Don't Delete`,
    }).then(async result => {
      if (result.isConfirmed) {
        // console.log(id);
        dispatch(deleteReport(id))
        setDeletingID(id)
        setIsDeleting(true)
        let Deletpromise = new Promise(async (resolve, reject) => {
          store.subscribe(() => {
            const deleteSuccess = store.getState()
            const status = deleteSuccess.Reports.deleteSuccess
            if (status == true) {
              // console.log(status);
              resolve("Done")
            }
          })
        })
        await Deletpromise.then(async msg => {
          // Toast.fire({
          //   icon: 'success',
          //   title: 'Delete Success!'
          // })
          // console.log("Delete Success");
          setIsDeleting(false)
          setDeletingID("")
          Swal.fire("Delete Success!", "", "success")
          // Toast.fire({
          //   icon: 'success',
          //   title: 'File Deleted'
          // })
          dispatch(setdeleteReportSuccess(false))
          setTimeout(() => {
            searchReport()
            // dispatch(getProjectData())
          }, 200)
        })
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info")
      }
    })
  }
  async function downloadReports(customerId, operatorId) {
    // console.log(customerId,customer);
    // console.log(operatorId);
    const customerLabel = customer.filter(obj => obj.id == customerId)[0].label
    const labelTemp = operator.filter(obj => obj.id == operatorId)
    // console.log(labelTemp);
    // const operatorLabel = `${labelTemp[0].label} - ${labelTemp[0].country}`;
    const operatorLabel = `${labelTemp[0].label}`
    // console.log(customerLabel);
    // console.log(operatorLabel);
    setModal_pipe_upload(true)
    var data = {
      customer_id: customerId,
      operator_id: operatorId,
      report_limit: 10,
    }
    const headers = {
      headers: {
        auth: JSON.parse(localStorage.getItem("authUser")),
      },
      responseType: "arraybuffer",
    }
    // console.log(headers);
    await axios
      .post(url._DOWNLOAD_REPORT, data, headers)
      .then(res => {
        // console.log(res);
        setModal_pipe_upload(false)
        // const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const contentType = "application/octet-stream"
        const blob = new Blob([res.data], { type: contentType })
        var link = document.createElement("a")
        link.href = URL.createObjectURL(blob)
        link.download = `NPT Results - ${customerLabel} - ${operatorLabel}.xlsx`
        link.click()
      })
      .catch(err => {
        console.log(err)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong on the Server.",
        })
        setModal_pipe_upload(false)
      })
    dispatch(downloadReport(data))
  }
  async function shareReports(id) {
    if (!id) {
      return
    } else {
      await shareReport({
        report_id: id,
      })
        .unwrap()
        .then(res => {
          Toast.fire({
            icon: "success",
            title: "Report Visibilty Updated",
          })
          setRefetchedReports(false)
          setTimeout(() => {
            searchReport()
            setRefetchedReports(true)
          }, 1000)
        })
        .catch(err => {
          Toast.fire({
            icon: "error",
            title: "Report Visibilty Update Failed",
          })
        })
    }
  }

  // console.log(selectedReports);

  useEffect(() => {
    dispatch(setSelectedReport([]))
    setCurrentPage(0)
    searchReport()
  }, [
    selectedCustomer,
    selectedOperator,
    selectedTemplate,
    selectedHist,
    sizePerPage,
  ])

  const searchReport = () => {
    dispatch(getReportsSuccess(false))
    dispatch(setReports([]))
    dispatch(
      searchReports({
        customer: selectedCustomer,
        operator: selectedOperator,
        template: selectedTemplate,
        history: selectedHist,
        size: sizePerPage,
      })
    )
  }

  return (
    <React.Fragment>
      {/* <MetaTags>
        <title>Edit Reports</title>
      </MetaTags> */}
      <div>
        <div className="pb-4">
          <div>
            <Row>
              <Row style={{ zIndex: "2", position: "relative" }}>
                <Col md="2">
                  <h5
                    style={{
                      paddingTop: "10px",
                    }}
                  >
                    Filters
                  </h5>
                </Col>
                <Col md="2">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="customer"
                    placeholder="Customer"
                    options={[
                      { value: "", label: "No Customer Selected" },
                      ...customer,
                    ]}
                    onChange={e => {
                      // console.log(e.id);
                      if (e.id) {
                        setCustomer(e.id)
                        setOperator("")
                        dispatch(
                          getNewOperators({
                            customer_id: e.id,
                          })
                        )
                      }
                      if (e.value === "") {
                        setOperator("")
                        setCustomer("")
                        dispatch(getNewOperators())
                      }
                    }}
                  />
                </Col>
                <Col md="2">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    value={operator.filter(obj => obj.id == selectedOperator)}
                    name="operator"
                    placeholder="Operator"
                    options={[
                      { value: "", label: "No Operator Selected" },
                      ...operator,
                    ]}
                    onChange={e => {
                      if (e.id) {
                        setOperator(e.id)
                      }
                      if (e.value === "") {
                        setOperator("")
                      }
                    }}
                  />
                </Col>
                <Col md="2">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="template"
                    // options={template}
                    options={[
                      { value: "", label: "No Template Selected" },
                      ...template,
                    ]}
                    placeholder="Template"
                    // onChange={(e) => {
                    //   setTemplate(e.id)
                    // }}
                    onChange={e => {
                      if (e.id) {
                        setTemplate(e.id)
                      }
                      if (e.value === "") {
                        setTemplate("")
                      }
                    }}
                  />
                </Col>
                <Col md="2">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="historical"
                    options={historicalData}
                    placeholder="Generated Reports"
                    onChange={e => {
                      // dispatch(filterReports({
                      //   filter :e.value
                      // }))
                      setSelectedHist(e.value)
                    }}
                  />
                </Col>
              </Row>
              <Col className="col-12">
                <div className="col-12 scrollable">
                  {reports?.length == 0 ? (
                    reportsGet_success == false ? (
                      <LoadingSpinner />
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                          paddingTop: "50px",
                        }}
                      >
                        No Data Found
                      </div>
                    )
                  ) : (
                    <Table className="table-responsive align-middle mb-0 report_table_edit">
                      <thead>
                        <tr>
                          <th>Report ID</th>
                          <th>Source Data</th>
                          <th>Tested Date</th>
                          <th>Generated By</th>
                          <th className="ps-4">Actions</th>
                          <th>Shared</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentPageItems?.map(file => {
                          return (
                            <tr key={file.id}>
                              <td>{file.id}</td>
                              <td
                                onClick={() => {
                                  scrollFunc()
                                  dispatch(setReportsGetSuccess(false))
                                  dispatch(setSelectedReport([]))
                                  dispatch(getReports({ id: file.id, template: "All"}))
                                  dispatch(setSelectedReportID(file.id))
                                  setLoading(true)
                                }}
                              >
                                {" "}
                                {file.report_name}{" "}
                              </td>
                              <td> {file.createdAt.slice(0, 10)} </td>
                              <td>
                                {" "}
                                {file.users_new?.name
                                  ? file.users_new.name
                                  : "- -"}{" "}
                              </td>
                              <td>
                                <div className="d-flex gap-3">
                                  {role !== 4 ? (
                                    <Link className="text-danger" to="#">
                                      {isDeleting && file.id === deletingID ? (
                                        <div
                                          className="spinner-border text-danger"
                                          role="status"
                                        >
                                          <span className="visually-hidden">
                                            Deleting...
                                          </span>
                                        </div>
                                      ) : !isDeleting ? (
                                        <i
                                          className="bx bx-x font-size-30"
                                          id="deletetooltip"
                                          onClick={() => deleteProj(file.id)}
                                        ></i>
                                      ) : (
                                        ""
                                      )}
                                    </Link>
                                  ) : (
                                    <i className="bx bx-x font-size-30 disabled-action text-danger"></i>
                                  )}
                                  {role !== 1 ? (
                                    <Link className="text-success" to="#">
                                      <i
                                        className="bx bx-down-arrow-alt font-size-30"
                                        id="edittooltip"
                                        onClick={() => {
                                          console.log("triggered download")
                                          downloadReports(
                                            file.customer_id,
                                            file.operator_id
                                          )
                                        }}
                                      ></i>
                                    </Link>
                                  ) : (
                                    <i className="bx bx-down-arrow-alt font-size-30 disabled-action text-success"></i>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="form-check form-switch form-switch-md mb-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    defaultChecked={file.share}
                                    disabled={shareReportLoading || role === 4 || !refetchedReports}
                                    id="customSwitchsizemd"
                                    onChange={() => shareReports(file.id)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customSwitchsizemd"
                                  ></label>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  )}
                </div>
                <div className="col-12">
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    forcePage={currentPage}
                    breakLabel={"..."}
                    pageCount={Math.ceil(reports?.length / itemsPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={data => {
                      setCurrentPage(data.selected)
                    }}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Modal isOpen={modal_pipe_upload}>
        <ModalHeader>Report Download</ModalHeader>
        <ModalBody>
          <LoadingSpinnerUpload />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default FilteredReports
