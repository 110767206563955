import React, { Component, useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import DeliveredTable from "./Delivered"
import NotDeliveredTable from "./notDelivered"
import KpiTable from "./ViewKpi"
import ChartTable from "./ViewKpi_charts"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import LoadingSpinner from "./loader"

import classnames from "classnames"
import { set } from "lodash"
const DeliveredTab = props => {
  const getSelectedSuccess = useSelector(
    state => state.Reports.getSelectedSuccess
  )
  //meta title
  const [deliveredOptions, setDeliveredOptions] = useState([])
  const [notDeliveredOptions, setNotDeliveredOptions] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState("All")

  useEffect(() => {
    if (props.deliveredReports) {
      // console.log("props.deliveredReports",props.deliveredReports);
      const delivered = props.deliveredReports.delivered
        ? props.deliveredReports.delivered
        : []
      const notDelivered = props.deliveredReports.not_delivered
        ? props.deliveredReports.not_delivered
        : []
      const deliveredTempOptions = delivered.reduce((acc, obj) => {
        Object.entries(obj).forEach(([key, value]) => {
          // if (value === "") {
          //   obj[key] = "-Empty-";
          // }
          if (!acc[key]) {
            acc[key] = []
          }
          if (!acc[key].includes(value)) {
            acc[key].push(value)
          }
          // if empty string, replace with "empty"
        })
        return acc
      }, {})
      const deliveredOptions = {}
      Object.entries(deliveredTempOptions).forEach(([key, values]) => {
        deliveredOptions[key] = values.reduce((acc, value, index) => {
          if (value === "") {
            acc["Empty"] = "Empty"
          } else if (value === null) {
            acc["Empty"] = "Empty"
          } else {
            if (key != "price") {
              acc[index] = value
            } else {
              if (value == 0 || value == "No Price") {
                acc[index] = value
              }
            }
          }
          return acc
        }, {})
      })
      setDeliveredOptions(deliveredOptions)
      // console.log("deliveredTempOptions",deliveredOptions);

      const notDeliveredTempOptions = notDelivered.reduce((acc, obj) => {
        Object.entries(obj).forEach(([key, value]) => {
          if (!acc[key]) {
            acc[key] = []
          }
          if (!acc[key].includes(value)) {
            acc[key].push(value)
          }
        })
        return acc
      }, {})
      const notDeliveredOptions = {}
      Object.entries(notDeliveredTempOptions).forEach(([key, values]) => {
        notDeliveredOptions[key] = values.reduce((acc, value, index) => {
          acc[index] = value
          return acc
        }, {})
      })
      setNotDeliveredOptions(notDeliveredOptions)
      //
    }
  }, [props.deliveredReports])

  // useEffect(() => {
  //   console.log("deliveredOptions",deliveredOptions);
  // }, [deliveredOptions,notDeliveredOptions]);

  // console.log(props.deliveredReports);

  const [activeTab1, setactiveTab1] = useState("5")
  const [toggle, toggleState] = useState(1)
  useEffect(() => {
    toggleState(1)
  }),
    [props.deliveredReports]
  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  return (
    <React.Fragment>
      <div className="col-12" style={{ paddingTop: "5px" }}>
        <Container fluid={true}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Justify Tabs</CardTitle> */}
                  <div className="ps-3 pe-3" style={{
                    display: "flex",
                  }}>
                    <Nav pills className="navtab-bg nav-justified" style={{ marginRight: "10px", width: "33%" }}>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            reportkpi_tab: "",
                            active: activeTab1 === "5",
                          })}
                          onClick={() => {
                            toggle1("5")
                          }}
                        >
                          Reports
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <Nav pills className="navtab-bg nav-justified" style={{ marginRight: "10px", width: "33%" }}>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "6",
                          })}
                          onClick={() => {
                            toggle1("6")
                          }}
                        >
                          KPI Table
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <Nav pills className="navtab-bg nav-justified" style={{ marginRight: "0px", width: "33%" }}>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "7",
                          })}
                          onClick={() => {
                            toggle1("7")
                          }}
                        >
                          KPI Charts
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>

                  {!(
                    props.deliveredReports.length === 0 && getSelectedSuccess
                  ) && (
                    <TabContent
                      activeTab={activeTab1}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="5" style={{}}>
                        <Row>
                          <Col>
                            <div>
                              {getSelectedSuccess ? (
                                <DeliveredTable
                                  deliveredReports={props.deliveredReports}
                                  highlights={props.deliveredReports}
                                  selectOptions={deliveredOptions}
                                />
                              ) : (
                                <LoadingSpinner />
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div>
                              {getSelectedSuccess ? (
                                <NotDeliveredTable
                                  notdeliveredReports={props.deliveredReports}
                                  highlights={props.deliveredReports}
                                  selectOptions={notDeliveredOptions}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="6">
                        <Row>
                          <Col>
                            <div>
                              {getSelectedSuccess ? (
                                <KpiTable
                                  selectedTemplate={selectedTemplate}
                                  setSelectedTemplate={setSelectedTemplate}
                                  setLoading={props.setLoading}
                                  kpiReports={props.deliveredReports}
                                />
                              ) : (
                                <LoadingSpinner />
                              )}
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="7">
                        <Row>
                          <Col>
                            <div>
                              {getSelectedSuccess ? (
                                <ChartTable
                                  selectedTemplate={selectedTemplate}
                                  setSelectedTemplate={setSelectedTemplate}
                                  setLoading={props.setLoading}
                                  kpiReports={props.deliveredReports}
                                />
                              ) : (
                                <LoadingSpinner />
                              )}
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DeliveredTab
