import React, { useEffect, useState } from "react"
import { Row, Col, Button } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { ContextMenuTrigger, ContextMenu, MenuItem } from "react-contextmenu"
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from "react-bootstrap-table2-editor"
import store from "../../../../store/index"
import { useDispatch, useSelector } from "react-redux"
import {
  getReports,
  updateReportSuccess,
  updateReports,
  regenKpi,
  setReportsGetSuccess,
} from "store/old/reports/actions"
import Swal from "sweetalert2"
import "toastr/build/toastr.min.css"
import filterFactory, {
  selectFilter,
  Comparator,
  textFilter,
} from "react-bootstrap-table2-filter"

import jwt from "jsonwebtoken"

// Custom select component

const products = [
  { id: 1, age: 25, qty: 1500, cost: 1000 },
  { id: 2, age: 34, qty: 1900, cost: 1300 },
  { id: 3, age: 67, qty: 1300, cost: 1300 },
  { id: 4, age: 23, qty: 1100, cost: 6400 },
  { id: 5, age: 78, qty: 1400, cost: 4000 },
]
const DeliveredTable = props => {
  const decodeToken = token => {
    try {
      const decodedToken = jwt.decode(token)
      return decodedToken
    } catch (error) {
      console.log("Error decoding token:", error)
      return null
    }
  }

  const token = localStorage.getItem("authUser")
  const decodedToken = decodeToken(JSON.parse(token))
  let role = decodedToken.role
  const cellEdit =
    role !== 4
      ? cellEditFactory({
          mode: "dbclick",
          blurToSave: true,
          afterSaveCell: (oldValue, newValue, row, column) => {
            // console.log(row)
          },
        })
      : undefined

  const dispatch = useDispatch()
  const reportID = useSelector(state => state.Reports.selectedReportId)
  var temp = props.highlights.highlights
  let [highlights, setHighlights] = useState(temp)  
  const filterOptions = props.selectOptions
  // console.log("filterOptions",filterOptions.other_channel);
  const [highlightCell, sethighlightCell] = useState(false)

  async function addHighlight(cellkey) {
    // console.log("add",highlightedCells);
    // console.log("add", highlights)
    // const temp = highlightedCells
    // console.log("before",temp);
    var data = {
      highlight_data: cellkey,
    }
    // highlights.push(data)
    setHighlights([...highlights, data])
    // console.log("highlighted",cellkey, highlights.filter(obj=>obj.highlight_data===cellkey));
    // temp.push(data)
    // setHighlightedCells(temp)
  }
  async function removeHighlight(cellkey) {
    // const temp = highlightedCells;
    // const indexoftemp = temp.indexOf(obj=>obj.highligh_data===cellkey);
    // console.log(cellkey);
    // console.log(highlights.filter(obj=>obj.highlight_data===cellkey));

    const indexoftemp = highlights.indexOf(
      obj => obj.highlight_data === cellkey
    )
    // console.log(indexoftemp);
    temp = highlights.filter(obj => obj.highlight_data != cellkey)
    setHighlights(temp)
    // console.log("un-highlighted",cellkey, highlights.filter(obj=>obj.highlight_data===cellkey));
    // highlights.splice(indexoftemp,1)
    // console.log(highlights.filter(obj=>obj.highlight_data===cellkey));
    // temp.pop(cellkey)
    // setHighlightedCells(temp)
  }
  function cellFormatter(cell, row, columnIndex) {
    const cellKey = `${row.id}-${columnIndex}`

    // console.log("props",highlights);
    // console.log("OGstate",highlightedCells);
    const isHighlighted = highlights
      ?.map(obj => obj.highlight_data)
      .includes(cellKey)
    // console.log("isHighlighted",isHighlighted);
    const backgroundColor = isHighlighted ? "yellow" : ""
    return (
      <div
        style={{ height: "100%",minHeight: '50px', backgroundColor }}
        id={`${row.id}+${columnIndex}+${cell}+div`}
      >
        {role !== 4 ? (
          <ContextMenuTrigger
            id={`cell-${row.id}-${columnIndex}`}
            collect={() => [row.id, columnIndex]}
            className={`${row.id}+${columnIndex}+${cell}+div}`}
            style={{ height: "100%", width: "300px" }}
          >
            <div style={{ height: "100%" }}>{cell}</div>
          </ContextMenuTrigger>
        ) : (
          <div style={{ height: "100%" }}>{cell}</div>
        )}
        <ContextMenu
          id={`cell-${row.id}-${columnIndex}`}
          className="custom-context-menu"
        >
          <MenuItem
            onClick={() => {
              const cellElement = document.getElementById(
                `${row.id}+${columnIndex}+${cell}+div`
              )
              if (
                !highlights?.map(obj => obj.highlight_data).includes(cellKey)
              ) {
                cellElement.style.backgroundColor = "yellow"
                addHighlight(cellKey)
                sethighlightCell(true)
              }
            }}
          >
            Highlight
          </MenuItem>
          <MenuItem
            onClick={() => {
              const cellElement = document.getElementById(
                `${row.id}+${columnIndex}+${cell}+div`
              )
              if (highlights.map(obj => obj.highlight_data).includes(cellKey)) {
                cellElement.style.backgroundColor = ""
                removeHighlight(cellKey)
                sethighlightCell(false)
              }
            }}
          >
            Un-Highlight
          </MenuItem>

          <MenuItem onClick={() => {}}>Edit cell</MenuItem>
        </ContextMenu>
      </div>
    )
  }
  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  // Apply styles if Safari
  if (isSafari) {
    var elements = document.querySelectorAll(
      ".react-bootstrap-table>table>thead>tr>th .filter-label"
    )
    elements.forEach(function (element) {
      element.style.marginLeft = "25%"
    })
  }

  const columns2 = [
    {
      dataField: "id",
      text: "ID",
      headerClasses: "id-header",
      style: { height: "2vh" },
      formatExtraData : highlights,
      formatter: (cell, row, i) => cellFormatter(cell, row, 0),
    },
    {
      dataField: "country",
      text: "Country",
      headerClasses: "country-header",
      // headerStyle: {width: '10%'},
      formatExtraData : highlights,
      formatter: (cell, row, i) => cellFormatter(cell, row, 1),
      // filter: selectFilter({
      //   placeholder: 'Select Country',
      //   defaultValue: 'all',
      //   options: {'all': 'All Countries',...filterOptions.country },
      //   withoutEmptyOption: true,
      //   onFilter: (filterVal, data) => {
      //     if (filterVal === 'all') {
      //       // If the filter value is 'all', return all rows
      //       return data;
      //     }
      //     else {
      //       // Otherwise, filter the rows based on the filter value
      //       if(filterVal === ''){
      //         return data.filter(row => row.country === "");
      //       }
      //       else{
      //         const value = filterOptions.country[filterVal];
      //         return data.filter(row => row.country === value);
      //       }
      //     }
      //   },
      // })
    },
    {
      dataField: "operator",
      text: "Operator",
      headerClasses: "operator-header",
      style: { height: "2vh" },
      // formatter: cellFormatter,
      formatExtraData : highlights,
      formatter: (cell, row) => cellFormatter(cell, row, 2),
      // filter: selectFilter({
      //   placeholder: 'Select Operator',
      //   defaultValue: 'all',
      //   options: {'all': 'All Operators',...filterOptions.operator},
      //   withoutEmptyOption: true,
      //   onFilter: (filterVal, data) => {
      //     if (filterVal === 'all') {
      //       // If the filter value is 'all', return all rows
      //       return data;
      //     }
      //     else {
      //       // filter the options based on the filterVal key
      //       if(filterVal === ''){
      //         return data.filter(row => row.operator === "");
      //       }
      //       else{
      //         const value = filterOptions.operator[filterVal];
      //         return data.filter(row => row.operator === value);
      //       }
      //     }
      //   }
      // })
    },
    {
      dataField: "is_delivered",
      text: "Is Delivered",
      style: { height: "2vh" },
      formatExtraData : highlights,
      formatter: (cell, row) => cellFormatter(cell, row, 3),
      headerFormatter: (column, colIndex,{sortElement, filterElement}) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Is Delivered</span>
            <span>
              <div>{sortElement}</div>
              <div className="tableFilter">{filterElement}</div>
            </span>
          </div>
        )
      },
      filter: selectFilter({
        options: { ...filterOptions.is_delivered, all: "All" },
        defaultValue: "all",
        withoutEmptyOption: true,
        onFilter: (filterVal, data) => {
          if (filterVal === "all") {
            // If the filter value is 'all', return all rows
            return data
          } else {
            // filter the options based on the filterVal key
            if (filterVal === "Empty") {
              return data.filter(row => row.is_delivered === "" || row.is_delivered === null)
            } else {
              const value = filterOptions.is_delivered[filterVal]
              return data.filter(row => row.is_delivered === value)
            }
          }
        },
      }),
      headerClasses: "is-delivered-header",
    },
    {
      dataField: "delivery_timestamp",
      text: "Delivery Timestamp",
      style: { height: "2vh" },
      headerClasses: "timestamp-header",
      // formatter: cellFormatter,
      formatExtraData : highlights,
      formatter: (cell, row) => cellFormatter(cell, row, 4),
    },
    {
      dataField: "delivery_duration",
      text: "Delivery Duration",
      style: { height: "2vh" },
      headerClasses: "delivery-header",
      // formatter: cellFormatter,
      formatExtraData : highlights,
      formatter: (cell, row) => cellFormatter(cell, row, 5),
    },
    {
      dataField: "b_number",
      text: "B Number",
      style: { height: "2vh" },
      headerClasses: "b_number-header",
      // formatter: cellFormatter,
      formatExtraData : highlights,
      formatter: (cell, row) => cellFormatter(cell, row, 6),
    },
    {
      dataField: "sender_id_type",
      text: "Sender ID Type",
      style: { height: "2vh" },
      headerClasses: "sender_id_type-header",
      // formatter: cellFormatter,
      formatExtraData : highlights,
      formatter: (cell, row) => cellFormatter(cell, row, 7),
    },
    {
      dataField: "price",
      text: "Price",
      style: { height: "2vh" },
      headerClasses: "price-header",
      formatExtraData : highlights,
      headerFormatter: (column, colIndex,{sortElement, filterElement}) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Price</span>
            <span>
              <div>{sortElement}</div>
              <div className="tableFilter">{filterElement}</div>
            </span>
          </div>
        )
      },
      formatter: (cell, row) => cellFormatter(cell, row, 8),
      filter: selectFilter({
        options: { ...filterOptions.price, all: "All Prices" },
        defaultValue: "all",
        withoutEmptyOption: true,
        onFilter: (filterVal, data) => {
          if (filterVal === "all") {
            // If the filter value is 'all', return all rows
            return data
          } else {
            // filter the options based on the filterVal key
            if (filterVal === "Empty") {
              return data.filter(row => row.price === "" || row.price === null)
            } else {
              const value = filterOptions.price[filterVal]
              return data.filter(row => row.price === value)
            }
          }
        },
      }),
    },
    {
      dataField: "sent_sender_id",
      text: "Sent Sender ID",
      style: { height: "2vh" },
      headerClasses: "sent_sender_id-header",
      // formatter: cellFormatter,
      formatExtraData : highlights,
      formatter: (cell, row) => cellFormatter(cell, row, 9),
    },
    {
      dataField: "received_sender_id",
      text: "Received Sender ID",
      style: { height: "2vh" },
      headerClasses: "received_sender_id-header",
      // formatter: cellFormatter,
      formatExtraData : highlights,
      formatter: (cell, row) => cellFormatter(cell, row, 10),
      filter: textFilter({
        placeholder: "Enter ID",
        comparator: Comparator.LIKE,
        caseSensitive: false,
        delay: 400,
      }),

    },
    {
      dataField: "is_sender_id_preserved",
      text: "Sender Preserved",
      style: { height: "2vh" },
      headerClasses: "is_sender_id_preserved-header",
      formatExtraData : highlights,
      headerFormatter: (column, colIndex,{sortElement, filterElement}) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Sender Preserved</span>
            <span>
              <div>{sortElement}</div>
              <div className="tableFilter">{filterElement}</div>
            </span>
          </div>
        )
      },
      formatter: (cell, row) => cellFormatter(cell, row, 11),
      filter: selectFilter({
        options: { ...filterOptions.is_sender_id_preserved, all: "All " },
        defaultValue: "all",
        withoutEmptyOption: true,
        onFilter: (filterVal, data) => {
          if (filterVal === "all") {
            // If the filter value is 'all', return all rows
            return data
          } else {
            // filter the options based on the filterVal key
            if (filterVal === "Empty") {
              return data.filter(
                row =>
                  row.is_sender_id_preserved === "" ||
                  row.is_sender_id_preserved === null
              )
            } else {
              const value = filterOptions.is_sender_id_preserved[filterVal]
              return data.filter(row => row.is_sender_id_preserved === value)
            }
          }
        },
      }),
    },
    {
      dataField: "smsc",
      text: "SMSC",
      style: { height: "2vh" },
      headerClasses: "smsc-header",
      formatExtraData : highlights,
      headerFormatter: (column, colIndex,{sortElement, filterElement}) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>SMSC</span>
            <span>
              <div>{sortElement}</div>
              <div className="tableFilter">{filterElement}</div>
            </span>
          </div>
        )
      },
      formatter: (cell, row) => cellFormatter(cell, row, 12),
      filter: selectFilter({
        options: { ...filterOptions.smsc, all: "All Options" },
        defaultValue: "all",
        withoutEmptyOption: true,
        onFilter: (filterVal, data) => {
          if (filterVal === "all") {
            // If the filter value is 'all', return all rows
            return data
          } else {
            // filter the options based on the filterVal key
            if (filterVal === "Empty") {
              return data.filter(row => row.smsc === "" || row.smsc === null)
            }
            if (filterVal === "null" || filterVal === null) {
              return data.filter(row => row.smsc === null)
            } else {
              const value = filterOptions.smsc[filterVal]
              return data.filter(row => row.smsc === value)
            }
          }
        },
      }),
    },
    {
      dataField: "expected_sms",
      text: "Expected SMSC",
      style: { height: "2vh" },
      headerClasses: "expected_sms-header",
      formatExtraData : highlights,
      headerFormatter: (column, colIndex, {sortElement, filterElement}) => {
        return(
          <div style={{ display: "flex", flexDirection: "column" }}>
          <span>Expected SMSC</span>
          <span>
            <div>{sortElement}</div>
            <div className="tableFilter">{filterElement}</div>
          </span>
        </div>
        )
      },
      formatter: (cell, row) => cellFormatter(cell, row, 13),
      filter: selectFilter({
        options: {...filterOptions.expected_sms, all: "All" },
        defaultValue: "all",
        withoutEmptyOption: true,
        onFilter: (filterVal, data) => {
          if (filterVal === "all") {
            // If the filter value is 'all', return all rows
            return data
          } else {
            // filter the options based on the filterVal key
            if (filterVal === "Empty") {
              return data.filter(row => row.expected_sms === "" || row.expected_sms === null)
            }
            if (filterVal === "null" || filterVal === null) {
              return data.filter(row => row.expected_sms === null)
            } else {
              const value = filterOptions.expected_sms[filterVal]
              return data.filter(row => row.expected_sms === value)
            }
          }
        },
      })

    },
    {
      dataField: "smsc_owner",
      text: "SMSC Owner",
      headerClasses: "smsc_owner-header",
      style: { height: "2vh" },
      formatExtraData : highlights,
      headerFormatter: (column, colIndex,{sortElement, filterElement}) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>SMSC Owner</span>
            <span>
              <div>{sortElement}</div>
              <div className="tableFilter">{filterElement}</div>
            </span>
          </div>
        )
      },
      formatter: (cell, row) => cellFormatter(cell, row, 14),
      filter: selectFilter({
        options: { ...filterOptions.smsc_owner, all: "All Options" },
        defaultValue: "all",
        withoutEmptyOption: true,
        onFilter: (filterVal, data) => {
          if (filterVal === "all") {
            // If the filter value is 'all', return all rows
            return data
          } else {
            // filter the options based on the filterVal key
            if (filterVal === "Empty") {
              return data.filter(
                row => row.smsc_owner === "" || row.smsc_owner === null
              )
            } else {
              const value = filterOptions.smsc_owner[filterVal]
              return data.filter(row => row.smsc_owner === value)
            }
          }
        },
      }),
    },
    {
      dataField: "sent_text",
      text: "Sent Text",
      style: { height: "2vh" },
      headerClasses: "sent_text-header",
      formatExtraData : highlights,
      // formatter: cellFormatter,
      formatter: (cell, row) => cellFormatter(cell, row, 15),
      filter: textFilter({
        placeholder:"Enter Text",
        className:"sent-filter",
        comparator: Comparator.LIKE,
        caseSensitive: false,
        delay: 400,
      })

    },
    {
      dataField: "received_text",
      text: "Received Text",
      style: { height: "2vh" },
      headerClasses: "received_text-header",
      formatExtraData : highlights,
      // formatter: cellFormatter,
      formatter: (cell, row) => cellFormatter(cell, row, 16),
      filter: textFilter({
        placeholder: "Enter Text",
        className: "received-filter",
        comparator: Comparator.LIKE,
        caseSensitive: false,
        delay: 400,

      })

    },
    {
      dataField: "is_text_preserved",
      text: "Text Preserved",
      style: { height: "2vh" },
      formatExtraData : highlights,
      headerClasses: "is_text_preserved-header",
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Text Preserved</span>
            <span>
              <div>{sortElement}</div>
              <div className="tableFilter">{filterElement}</div>
            </span>
          </div>
        )
      },
      formatter: (cell, row) => cellFormatter(cell, row, 17),
      filter: selectFilter({
        options: { ...filterOptions.is_text_preserved, all: "All " },
        defaultValue: "all",
        withoutEmptyOption: true,
        onFilter: (filterVal, data) => {
          if (filterVal === "all") {
            // If the filter value is 'all', return all rows
            return data
          } else {
            // filter the options based on the filterVal key
            if (filterVal === "Empty") {
              return data.filter(
                row =>
                  row.is_text_preserved === "" || row.is_text_preserved === null
              )
            } else {
              const value = filterOptions.is_text_preserved[filterVal]
              return data.filter(row => row.is_text_preserved === value)
            }
          }
        },
      }),
    },
    {
      dataField: "template_spoofing",
      text: "Template Spoofing",
      style: { height: "2vh" },
      headerClasses: "is-delivered-header",
      formatExtraData : highlights,
      headerFormatter: (column, colIndex,{sortElement, filterElement}) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Template Spoofing</span>
            <span>
              <div>{sortElement}</div>
              <div className="tableFilter">{filterElement}</div>
            </span>
          </div>
        )
      },
      formatter: (cell, row) => cellFormatter(cell, row, 18),
      editable: true,
      filter: selectFilter({
        options: { ...filterOptions.template_spoofing, all: "All" },
        defaultValue: "all",
        withoutEmptyOption: true,
        onFilter: (filterVal, data) => {
          if (filterVal === "all") {
            // If the filter value is 'all', return all rows
            return data
          } else {
            // filter the options based on the filterVal key
            if (filterVal === "Empty") {
              return data.filter(row => row.template_spoofing === "" || row.template_spoofing === null)
            } else {
              const value = filterOptions.template_spoofing[filterVal]
              return data.filter(row => row.template_spoofing === value)
            }
          }
        },
      }),
    },
    {
      dataField: "gate",
      text: "Gate",
      style: { height: "2vh" },
      headerClasses: "gate-header",
      formatExtraData : highlights,
      headerFormatter: (column, colIndex,{sortElement, filterElement}) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Gate</span>
            <span>
              <div>{sortElement}</div>
              <div className="tableFilter">{filterElement}</div>
            </span>
          </div>
        )
      },
      // formatter: (cell, row) => cellFormatter(cell, row, 18),
      editable: false,
      filter: textFilter({
        placeholder: "Enter Gate",
        className: "gate-filter",
        comparator: Comparator.LIKE,
        caseSensitive: false,
        delay: 400,
      }),
    },
    {
      dataField: "partner",
      text: "Partner",
      style: { height: "2vh" },
      formatExtraData : highlights,
      formatter: (cell, row) => cellFormatter(cell, row),
      headerClasses: "is-delivered-header",
    },
    {
      dataField: "other_channel",
      text: "Other Channel Delivery",
      style: { height: "2vh" },
      formatExtraData : highlights,
      headerClasses: "is-delivered-header",
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Other Channel Delivery</span>
            <span>
              <div>{sortElement}</div>
              <div className="tableFilter">{filterElement}</div>
            </span>
          </div>
        )
      },
      // formatter: (cell, row) => cellFormatter(cell, row, 19),
      filter: selectFilter({
        options: filterOptions && filterOptions.other_channel ? { 
          ...Object.fromEntries(
            Object.entries(filterOptions.other_channel).map(([key, value]) => [key, String(value)])
          ), 
          all: "All " 
        } : {},
        defaultValue: "all",
        withoutEmptyOption: true,
        onFilter: (filterVal, data) => {
          // console.log(filterVal);
          if (filterVal === "all") {
            return data
          } else {
            // filter the options based on the filterVal key
            if (filterVal === "Empty") {
              return data.filter(
                row =>
                  row.other_channel === "" || row.other_channel === null
              )
            } else {
              const value = filterOptions.other_channel[filterVal]
              // console.log(value);
              return data.filter(row => row.other_channel == value)
            }
          }
        },
      }),
    },
    {
      dataField: "simbox_onnet",
      text: "SIM box & on-net Delivery",
      style: { height: "2vh" },
      formatExtraData : highlights,
      headerClasses: "is-delivered-header",
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>SIM box & on-net Delivery</span>
            <span>
              <div>{sortElement}</div>
              <div className="tableFilter">{filterElement}</div>
            </span>
          </div>
        )
      },
      filter: selectFilter({
        options: filterOptions && filterOptions.simbox_onnet ? { 
          ...Object.fromEntries(
            Object.entries(filterOptions.simbox_onnet).map(([key, value]) => [key, String(value)])
          ), 
          all: "All " 
        } : {},
        defaultValue: "all",
        withoutEmptyOption: true,
        onFilter: (filterVal, data) => {
          // console.log(filterVal);
          if (filterVal === "all") {
            return data
          } else {
            // filter the options based on the filterVal key
            if (filterVal === "Empty") {
              return data.filter(
                row =>
                  row.simbox_onnet === "" || row.simbox_onnet === null
              )
            } else {
              const value = filterOptions.simbox_onnet[filterVal]
              // console.log(value);
              return data.filter(row => row.simbox_onnet == value)
            }
          }
        },
      }),
    },
    {
      dataField: "simbox_offnet",
      text: "no SIM box & on-net non-official Delivery",
      style: { height: "2vh" },
      formatExtraData : highlights,
      headerClasses: "b_number-header",
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>no SIM box & on-net non-official Delivery</span>
            <span>
              <div>{sortElement}</div>
              <div className="tableFilter">{filterElement}</div>
            </span>
          </div>
        )
      },
      filter: selectFilter({
        options: filterOptions && filterOptions.simbox_offnet ? { 
          ...Object.fromEntries(
            Object.entries(filterOptions.simbox_offnet).map(([key, value]) => [key, String(value)])
          ), 
          all: "All " 
        } : {},
        defaultValue: "all",
        withoutEmptyOption: true,
        onFilter: (filterVal, data) => {
          // console.log(filterVal);
          if (filterVal === "all") {
            return data
          } else {
            // filter the options based on the filterVal key
            if (filterVal === "Empty") {
              return data.filter(
                row =>
                  row.simbox_offnet === "" || row.simbox_offnet === null
              )
            } else {
              const value = filterOptions.simbox_offnet[filterVal]
              // console.log(value);
              return data.filter(row => row.simbox_offnet == value)
            }
          }
        },
      }),
    },
    {
      dataField: "sim_national_offnet",
      text: "SIM box & national off-net Delivery",
      style: { height: "2vh" },
      formatExtraData : highlights,
      headerClasses: "b_number-header",
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>SIM box & national off-net Delivery</span>
            <span>
              <div>{sortElement}</div>
              <div className="tableFilter">{filterElement}</div>
            </span>
          </div>
        )
      },
      filter: selectFilter({
        options: filterOptions && filterOptions.sim_national_offnet ? { 
          ...Object.fromEntries(
            Object.entries(filterOptions.sim_national_offnet).map(([key, value]) => [key, String(value)])
          ), 
          all: "All " 
        } : {},
        defaultValue: "all",
        withoutEmptyOption: true,
        onFilter: (filterVal, data) => {
          // console.log(filterVal);
          if (filterVal === "all") {
            return data
          } else {
            // filter the options based on the filterVal key
            if (filterVal === "Empty") {
              return data.filter(
                row =>
                  row.sim_national_offnet === "" || row.sim_national_offnet === null
              )
            } else {
              const value = filterOptions.sim_national_offnet[filterVal]
              // console.log(value);
              return data.filter(row => row.sim_national_offnet == value)
            }
          }
        },
      }),
    },
    {
      dataField: "nosim_national_offnet",
      text: "no SIM box & national off-net Delivery",
      style: { height: "2vh" },
      formatExtraData : highlights,
      headerClasses: "b_number-header",
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>no SIM box & national off-net Delivery</span>
            <span>
              <div>{sortElement}</div>
              <div className="tableFilter">{filterElement}</div>
            </span>
          </div>
        )
      },
      filter: selectFilter({
        options: filterOptions && filterOptions.nosim_national_offnet ? { 
          ...Object.fromEntries(
            Object.entries(filterOptions.nosim_national_offnet).map(([key, value]) => [key, String(value)])
          ), 
          all: "All " 
        } : {},
        defaultValue: "all",
        withoutEmptyOption: true,
        onFilter: (filterVal, data) => {
          // console.log(filterVal);
          if (filterVal === "all") {
            return data
          } else {
            // filter the options based on the filterVal key
            if (filterVal === "Empty") {
              return data.filter(
                row =>
                  row.nosim_national_offnet === "" || row.nosim_national_offnet === null
              )
            } else {
              const value = filterOptions.nosim_national_offnet[filterVal]
              // console.log(value);
              return data.filter(row => row.nosim_national_offnet == value)
            }
          }
        },
      }),
    },
    {
      dataField: "international",
      text: "International Delivery",
      style: { height: "2vh" },
      formatExtraData : highlights,
      headerClasses: "b_number-header",
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>International Delivery</span>
            <span>
              <div>{sortElement}</div>
              <div className="tableFilter">{filterElement}</div>
            </span>
          </div>
        )
      },
      filter: selectFilter({
        options: filterOptions && filterOptions.international ? { 
          ...Object.fromEntries(
            Object.entries(filterOptions.international).map(([key, value]) => [key, String(value)])
          ), 
          all: "All " 
        } : {},
        defaultValue: "all",
        withoutEmptyOption: true,
        onFilter: (filterVal, data) => {
          // console.log(filterVal);
          if (filterVal === "all") {
            return data
          } else {
            // filter the options based on the filterVal key
            if (filterVal === "Empty") {
              return data.filter(
                row =>
                  row.international === "" || row.international === null
              )
            } else {
              const value = filterOptions.international[filterVal]
              // console.log(value);
              return data.filter(row => row.international == value)
            }
          }
        },
      }),
    },
    {
      dataField: "bypass",
      text: "Bypass Delivery",
      style: { height: "2vh" },
      formatExtraData : highlights,
      headerClasses: "is-delivered-header",
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Bypass Delivery</span>
            <span>
              <div>{sortElement}</div>
              <div className="tableFilter">{filterElement}</div>
            </span>
          </div>
        )
      },
      filter: selectFilter({
        options: filterOptions && filterOptions.bypass ? { 
          ...Object.fromEntries(
            Object.entries(filterOptions.bypass).map(([key, value]) => [key, String(value)])
          ), 
          all: "All " 
        } : {},
        defaultValue: "all",
        withoutEmptyOption: true,
        onFilter: (filterVal, data) => {
          // console.log(filterVal);
          if (filterVal === "all") {
            return data
          } else {
            // filter the options based on the filterVal key
            if (filterVal === "Empty") {
              return data.filter(
                row =>
                  row.bypass === "" || row.bypass === null
              )
            } else {
              const value = filterOptions.bypass[filterVal]
              // console.log(value);
              return data.filter(row => row.bypass == value)
            }
          }
        },
      }),
    },
    // {
    //   dataField: "batch_id",
    //   text:"Batch ID",
    //   style: { height: "2vh" },
    //   formatter: (cell, row) => cellFormatter(cell, row, 19)
    // },
    // {
    //   dataField:"createdAt",
    //   text:"Created At",
    //   style: { height: "2vh" },
    //   formatter: (cell, row) => cellFormatter(cell, row, 20)
    // },
  ]

  function showToast() {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: "5000",
      extendedTimeOut: "1000",
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: false,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: "500",
      hideDuration: "1000",
    }

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    toastr.success("Values Updated", "Edit Successful")
  }
  var uniqueBatchIDs = ""
  const Reports = props.deliveredReports
  if (Reports.length != 0) {
    var batchIDs = Reports.delivered.map(obj => obj.batch_id)
    uniqueBatchIDs = Array.from(new Set(batchIDs))
    // console.log(uniqueBatchIDs);
  }
  var reportData = Reports.length == 0 ? products : Reports.delivered
  // useEffect(() => {
  //   console.log(reportID)
  // }, [reportID])

  async function sendEdits() {
    var formData = new FormData()
    //console.log(JSON.stringify(reportData));
    if (Reports.length != 0) {
      // console.log("added", highlights.filter(obj=>obj.highlight_data=='544536-4'));
      // console.log("removed",highlights.filter(obj=>obj.highlight_data=='544536-3'));

      formData.append("data", JSON.stringify(reportData))
      formData.append("highlights", JSON.stringify(highlights))
      formData.append("customer", "selectedCustomer")
      formData.append("report_id", JSON.stringify(reportID))
      dispatch(updateReports(formData))
      dispatch(updateReportSuccess(false))
      dispatch(setReportsGetSuccess(false))
      let editPromise = new Promise((resolve, reject) => {
        store.subscribe(() => {
          const updateSuccess = store.getState()
          const status = updateSuccess.Reports.updateSuccess
          if (status == true) {
            resolve("Success!")
          }
          if (status == "error") {
            reject("Error!")
          }
        })
      })
      await editPromise
        .then(async message => {
          console.log("Report Edit success")
          showToast()
          dispatch(getReports({id: reportID, template: "All"}))
          dispatch(updateReportSuccess(false))
          // await genKPI();
        })
        .catch(message => {
          Swal.fire({
            title: "Error!",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonText: "OK",
          })
          dispatch(getReports(reportID))
          dispatch(updateReportSuccess(false))
        })
      // setTimeout(() => {
      //   showToast()
      //   dispatch(getReports(reportID))
      // }, 2000);
    }
  }
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // console.log(`Clicked row ${rowIndex}, column ${row[e.target.cellIndex]}`)
    },
  }

  return (
    <React.Fragment>
      <div className="col-12" style={{ paddingTop: "5px" }}>
        <div>
          {/* <Breadcrumbs title="Tables" breadcrumbItem="Editable Table" /> */}

          <Row>
            <Col>
              {/* <CardTitle className="deli-nondil-title">DELIVERED </CardTitle> */}
              <div className="modal-header">
                <h5>DELIVERED</h5>
              </div>

              <div className="table-responsive scrollable table_shadow_div reportdata_table">
                <BootstrapTable
                  keyField="id"
                  data={reportData}
                  columns={columns2}
                  bootstrap4={true}
                  rowEvents={rowEvents}
                  filter={filterFactory()}
                  cellEdit={cellEdit}
                  // onCellClick={(row, cell, rowIndex, columnIndex) =>
                  //   handleCellClick(row.id, columnIndex, cell)
                  // }
                />
              </div>
              <br />
              <Col className="position-relative ">
                <Button
                  color="danger"
                  paddingTop="50px"
                  className="btn btn-custom md-position-absolute ms-auto p-2 bd-highlight"
                  onClick={() => {
                    sendEdits()
                  }}
                  disabled={role === 4}
                >
                  Accept Edits
                </Button>
              </Col>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DeliveredTable
