import React, { useEffect, useState } from "react"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import Swal from "sweetalert2"
import DeliveryKpiApexChart from "pages/AllCharts/apex/deliverykpi"
import PriceKpiApexChart from "pages/AllCharts/apex/pricingkpi"
import TimeKpiApexChart from "pages/AllCharts/apex/time_kpi"
import MtrKpiApexChart from "pages/AllCharts/apex/mtr_kpi"
import store from "../../../../store/index"
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from "react-bootstrap-table2-editor"
import {
  getReports,
  updateKpi,
  updateKpiSuccess,
  setReportsGetSuccess,
  setSelectedReportID,
} from "store/old/reports/actions"
import { useDispatch, useSelector } from "react-redux"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css"
import jwt from "jsonwebtoken"
import Select from "react-select"
import { components } from "react-select"
import _ from "lodash"

// const sampledata = [
//   {
//     test_date: "2022-01",
//     template: "Facebook",
//     tested_routes: 618,
//     test_per_routes: 1,
//     delivered_sms: 124,
//     delivery_ratio: 20.06 + "%",
//     total_dlrs: 579,
//     positive_dlrs: 186,
//     negative_dlrs: 393,
//     honest_positive_dlr: 123,
//     delivery_ratio_above_mtr: 63.33 + "%",
//     delivery_ratio_below_mtr: 17.35 + "%",
//     honest_positive_dlr_ratio: 66.13 + "%",
//     fakedlrs: 63,
//     fakedlr_ratio_above_mtr: 16.67 + "%",
//     fakedlr_ratio_under_mtr: 42.65 + "%",
//     fakedlr_positivedlr_ratio: 33.87 + "%",
//     sender_mod_ratio: 15.32 + "%",
//     content_mod_ratio: 13.71 + "%",
//     content_mod_level: 3,
//     template_spoofing: 15,
//     other_channel_deliveries: 2,
//     sim_nonet_smsc: 0,
//     nonet_nonoffic_smsc_nosim: 9,
//     simbox_nationaloffnetsmsc: 0,
//     national_smsc_without_sim: 0,
//     internationalsmsc: 0,
//     bypass_ratio: 8.87 + "%",
//     official_mtr_usd: 0.065 + "$",
//     exchange_rate: 0.8646,
//     official_mtr_eur: 0.0562 + "€",
//     avg_price_official_routes: 0.0424 + "€",
//     avg_price_dlv_routes: 0.0424 + "€",
//     dlv_routes_below_mtr: 60.48 + "%",
//     avg_dlv_time: "00:00:36",
//     routes_dlvtime_less_1: 99.19 + "%",
//     routes_dlvtime_less_5: 100 + "%",
//   },

//   {
//     test_date: "2021-12",
//     template: "Facebook",
//     tested_routes: 618,
//     test_per_routes: 1,
//     delivered_sms: 110,
//     delivery_ratio: 17.8 + "%",
//     total_dlrs: 557,
//     positive_dlrs: 178,
//     negative_dlrs: 379,
//     honest_positive_dlr: 108,
//     delivery_ratio_above_mtr: 66.67 + "%",
//     delivery_ratio_below_mtr: 14.8 + "%",
//     honest_positive_dlr_ratio: 60.67 + "%",
//     fakedlrs: 70,
//     fakedlr_ratio_above_mtr: 13.33 + "%",
//     fakedlr_ratio_under_mtr: 45.2 + "%",
//     fakedlr_positivedlr_ratio: 39.33 + "%",
//     sender_mod_ratio: 10.0 + "%",
//     content_mod_ratio: 8.18 + "%",
//     content_mod_level: 3,
//     template_spoofing: 7,
//     other_channel_deliveries: 2,
//     sim_nonet_smsc: 0,
//     nonet_nonoffic_smsc_nosim: 5,
//     simbox_nationaloffnetsmsc: 0,
//     national_smsc_without_sim: 0,
//     internationalsmsc: 0,
//     bypass_ratio: 6.36 + "%",
//     official_mtr_usd: 0.065 + "$",
//     exchange_rate: 0.8646,
//     official_mtr_eur: 0.0562 + "€",
//     avg_price_official_routes: 0.0433 + "€",
//     avg_price_dlv_routes: 0.0433 + "€",
//     dlv_routes_below_mtr: 59.09 + "%",
//     avg_dlv_time: "00:00:37",
//     routes_dlvtime_less_1: 99.09 + "%",
//     routes_dlvtime_less_5: 100 + "%",
//   },

//   {
//     test_date: "2021-07",
//     template: "Google",
//     tested_routes: 565,
//     test_per_routes: 1,
//     delivered_sms: 116,
//     delivery_ratio: 20.53 + "%",
//     total_dlrs: 513,
//     positive_dlrs: 182,
//     negative_dlrs: 331,
//     honest_positive_dlr: 113,
//     delivery_ratio_above_mtr: 63.33 + "%",
//     delivery_ratio_below_mtr: 17.94 + "%",
//     honest_positive_dlr_ratio: 62.09 + "%",
//     fakedlrs: 69,
//     fakedlr_ratio_above_mtr: 16.67 + "%",
//     fakedlr_ratio_under_mtr: 42.06 + "%",
//     fakedlr_positivedlr_ratio: 37.91 + "%",
//     sender_mod_ratio: 27.59 + "%",
//     content_mod_ratio: 22.41 + "%",
//     content_mod_level: 3,
//     template_spoofing: 0,
//     other_channel_deliveries: 0,
//     sim_nonet_smsc: 1,
//     nonet_nonoffic_smsc_nosim: 2,
//     simbox_nationaloffnetsmsc: 27,
//     national_smsc_without_sim: 0,
//     internationalsmsc: 0,
//     bypass_ratio: 25.86 + "%",
//     official_mtr_usd: 0.055 + "$",
//     exchange_rate: 0.8646,
//     official_mtr_eur: 0.0476 + "€",
//     avg_price_official_routes: 0.0383 + "€",
//     avg_price_dlv_routes: 0.0333 + "€",
//     dlv_routes_below_mtr: 62.07 + "%",
//     avg_dlv_time: "00:02:31",
//     routes_dlvtime_less_1: 50.0 + "%",
//     routes_dlvtime_less_5: 99.14 + "%",
//   },

//   {
//     test_date: "2021-04",
//     template: "Google",
//     tested_routes: 565,
//     test_per_routes: 1,
//     delivered_sms: 152,
//     delivery_ratio: 27.39 + "%",
//     total_dlrs: 470,
//     positive_dlrs: 242,
//     negative_dlrs: 228,
//     honest_positive_dlr: 148,
//     delivery_ratio_above_mtr: 58.33 + "%",
//     delivery_ratio_below_mtr: 20.38 + "%",
//     honest_positive_dlr_ratio: 61.16 + "%",
//     fakedlrs: 94,
//     fakedlr_ratio_above_mtr: 21.67 + "%",
//     fakedlr_ratio_under_mtr: 39.62 + "%",
//     fakedlr_positivedlr_ratio: 38.48 + "%",
//     sender_mod_ratio: 6.58 + "%",
//     content_mod_ratio: 4.61 + "%",
//     content_mod_level: 2,
//     template_spoofing: 0,
//     other_channel_deliveries: 0,
//     sim_nonet_smsc: 4,
//     nonet_nonoffic_smsc_nosim: 1,
//     simbox_nationaloffnetsmsc: 0,
//     national_smsc_without_sim: 0,
//     internationalsmsc: 0,
//     bypass_ratio: 3.29 + "%",
//     official_mtr_usd: 0.055 + "$",
//     exchange_rate: 0.8646,
//     official_mtr_eur: 0.0476 + "€",
//     avg_price_official_routes: 0.04 + "€",
//     avg_price_dlv_routes: 0.04 + "€",
//     dlv_routes_below_mtr: 52.63 + "%",
//     avg_dlv_time: "00:01:50",
//     routes_dlvtime_less_1: 539.47 + "%",
//     routes_dlvtime_less_5: 99.34 + "%",
//   },
// ]

const sampledata = []

const KpiTable = props => {
  const decodeToken = token => {
    try {
      const decodedToken = jwt.decode(token)
      return decodedToken
    } catch (error) {
      console.log("Error decoding token:", error)
      return null
    }
  }

  const token = localStorage.getItem("authUser")
  const decodedToken = decodeToken(JSON.parse(token))
  let role = decodedToken.role
  const selectedTemplate = props.selectedTemplate
  const setSelectedTemplate = props.setSelectedTemplate
  const reportID = useSelector(state => state.Reports.selectedReportId)
  const updateSuccess = useSelector(state => state.Reports.updateSuccess)
  const kpi = props.kpiReports
  const kpiTemplateOptions = props.kpiReports.template_options
  // console.log("kpi",kpi?.kpidata);
  // const kpi_dates=kpi.test_date;
  const kpi_dates = kpi?.kpidata?.map(obj => obj.test_date).reverse()
  // console.log("kpi_dates",kpi_dates);
  var kpiCheck = kpi.length == 0 ? sampledata : kpi.kpidata
  var kpiData = kpiCheck.length == 0 ? sampledata : kpiCheck
  const dispatch = useDispatch()
  const [sortedOptions, setSortedOptions] = useState([])

  // useEffect(() => {
  //   console.log("kpiData",kpiData);
  // }, [kpiData])

  useEffect(() => {
    if (updateSuccess) {
      setSelectedTemplate("All")
    }
  }, [updateSuccess])

  const columnskpi = [
    {
      dataField: "report_id",
      text: "Report ID",
      editable: false,
      headerStyle: () => ({ backgroundColor: "#f5f5f5" }),
      style: (cell, row, rowIndex, colIndex) => {
        if (row.report_id === reportID) {
          return { backgroundColor: "yellow" }
        }
        return {}
      },
    },
    {
      dataField: "test_date",
      editable: false,
      text: "Test date",
      formatter: (cell, row) => {
        return <div style={{ width: "100px" }}>{cell}</div>
      },
    },
    {
      dataField: "template",
      editable: false,
      text: "Template",
    },
    {
      dataField: "tested_routes",
      editable: false,
      text: "# Tested routes",
      headerClasses: "min-width-th-90",
    },
    {
      dataField: "test_per_routes",
      editable: false,
      text: "# Test per route",
      headerClasses: "min-width-th-70",
    },
    {
      dataField: "delivered_sms",
      editable: false,
      text: "# Delivered SMS",
      headerClasses: "min-width-th-110",
    },
    {
      dataField: "delivery_ratio",
      editable: false,
      text: "Delivery ratio - all routes (%)",
      headerClasses: "min-width-th-90",
    },
    {
      dataField: "delivered_sms_below_mtr",
      text: "# Delivered SMS - below MTR",
      headerClasses: "min-width-th-110",
      editable: false,
    },
    {
      dataField: "dlv_routes_below_mtr",
      editable: false,
      text: "Delivered SMS - ratio below MTR (%)",
      headerClasses: "min-width-th-100",
    },
    {
      dataField: "delivery_ratio_above_mtr",
      text: "Delivery ratio - routes above MTR (%)",
      headerClasses: "min-width-th-100",
      editable: false,
    },
    {
      dataField: "delivery_ratio_below_mtr",
      text: "Delivery ratio - routes below MTR (%)",
      headerClasses: "min-width-th-100",
      editable: false,
    },
    {
      dataField: "total_dlrs",
      text: "# Total DLRs",
      headerClasses: "min-width-th-80",
      editable: false,
    },
    {
      dataField: "positive_dlrs",
      editable: false,
      text: "# Positive DLRs",
      headerClasses: "min-width-th-100",
    },
    {
      dataField: "negative_dlrs",
      text: "# Negative DLRs",
      editable: false,
      headerClasses: "min-width-th-100",
    },
    {
      dataField: "honest_positive_dlr",
      text: "# Honest positive DLRs",
      editable: false,
      headerClasses: "min-width-th-90",
    },
    {
      dataField: "honest_positive_dlr_ratio",
      text: "Honest positive DLR ratio (%)",
      editable: false,
    },
    {
      dataField: "fakedlrs",
      text: "# Fake DLRs",
      editable: false,
      headerClasses: "min-width-th-80",
    },
    {
      dataField: "fakedlr_positivedlr_ratio",
      editable: false,
      text: "Fake DLR ratio (%)",
    },
    {
      dataField: "fakedlr_ratio_above_mtr",
      text: "Fake DLR ratio - routes above MTR (%)",
      editable: false,
      headerClasses: "min-width-th-100",
    },
    {
      dataField: "fakedlr_ratio_under_mtr",
      editable: false,
      text: "Fake DLR ratio - routes below MTR (%)",
      headerClasses: "min-width-th-100",
    },
    {
      dataField: "sender_mod_ratio",
      editable: false,
      text: "Sender modification ratio (%)",
    },
    {
      dataField: "content_mod_ratio",
      editable: false,
      text: "Content modification ratio (%)",
    },
    {
      dataField: "content_mod_level",
      editable: role !== 4,
      text: "Content modification level",
    },
    {
      dataField: "template_spoofing",
      editable: role !== 4,
      text: "# Template spoofing",
      headerClasses: "min-width-th-110",
    },
    {
      dataField: "other_channel_deliveries",
      editable: role !== 4,
      text: "# Other channel deliveries",
    },
    {
      dataField: "sim_nonet_smsc",
      editable: false,
      text: "# On-net SIM",
      headerClasses: "min-width-th-70",
    },
    {
      dataField: "nonet_nonoffic_smsc_nosim",
      editable: false,
      text: "# On-net unofficial SMSC",
      headerClasses: "min-width-th-90",
    },
    {
      dataField: "simbox_nationaloffnetsmsc",
      editable: false,
      text: "# Off-net SIM",
      headerClasses: "min-width-th-80",
    },
    {
      dataField: "national_smsc_without_sim",
      editable: false,
      text: "# Off-net unofficial SMSC",
      headerClasses: "min-width-th-90",
    },
    {
      dataField: "internationalsmsc",
      editable: false,
      text: "# International SMSC",
      headerClasses: "min-width-th-130",
    },
    {
      dataField: "bypass_ratio",
      editable: false,
      text: "Bypass ratio (%)",
      headerClasses: "min-width-th-90",
    },
    // {
    //   dataField: "official_mtr_usd",
    //   text:"Official MTR (USD)"
    // },
    // {
    //   dataField:"exchange_rate",
    //   text:"Exchange Rate"
    // },
    {
      dataField: "currency",
      editable: false,
      text: "Currency",
      classes: "text-align-left",
    },
    {
      dataField: "official_mtr_eur",
      editable: false,
      text: "Official MTR",
    },
    {
      dataField: "avg_price_dlv_routes",
      editable: false,
      text: "Avg. price of delivering routes",
    },
    {
      dataField: "avg_price_official_routes",
      editable: false,
      text: "Avg. price of official routes",
    },

    {
      dataField: "avg_dlv_time",
      editable: false,
      text: "Avg. delivery time",
    },
    {
      dataField: "routes_dlvtime_less_1",
      editable: false,
      text: "Routes with delivery time < 1min (%)",
      headerClasses: "min-width-th-100",
    },
    {
      dataField: "routes_dlvtime_less_5",
      editable: false,
      text: "Routes with delivery time < 5min (%)",
      headerClasses: "min-width-th-100",
    },
  ]

  function showToast() {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: "5000",
      extendedTimeOut: "1000",
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: false,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: "500",
      hideDuration: "1000",
    }

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    toastr.success("Values Updated", "Edit Successful")
  }

  async function sendEdits() {
    var formData = new FormData()
    //console.log(JSON.stringify(reportData));
    if (kpi.length != 0) {
      // console.log("kpiData",kpiData);
      formData.append("data", JSON.stringify(kpiData))
      formData.append("report_id", JSON.stringify(reportID))
      formData.append("customer", "selectedCustomer")
      dispatch(setReportsGetSuccess(false))
      dispatch(updateKpi(formData))
      dispatch(updateKpiSuccess(false))
      let kpiPromise = new Promise((resolve, reject) => {
        store.subscribe(() => {
          const updateSuccess = store.getState()
          const status = updateSuccess.Reports.updateKpiSuccess
          if (status == true) {
            resolve("Success!")
          }
          if (status == "error") {
            reject("Error!")
          }
        })
      })
      await kpiPromise
        .then(async message => {
          // console.log("KPI Edit success");
          showToast()
          // dispatch(getReports(reportID))
          dispatch(getReports({ id: reportID, template: selectedTemplate }))
          dispatch(updateKpiSuccess(false))
          // await genKPI();
        })
        .catch(message => {
          Swal.fire({
            title: "Error!",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonText: "OK",
          })
          dispatch(getReports(reportID))
          dispatch(updateKpiSuccess(false))
        })
    }
  }

  useEffect(() => {
    if (kpiTemplateOptions) {
      const sorted = [...kpiTemplateOptions].sort((a, b) =>
        a.label.localeCompare(b.label)
      )
      setSortedOptions(sorted)
    }
  }, [kpiTemplateOptions])

  const DropdownIndicator = props => {
    return (
      // props.selectProps.value ? null : (
      <components.DropdownIndicator {...props}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.0011 12V19.88C14.0411 20.18 13.9411 20.5 13.7111 20.71C13.6186 20.8027 13.5087 20.8762 13.3877 20.9264C13.2668 20.9766 13.1371 21.0024 13.0061 21.0024C12.8751 21.0024 12.7455 20.9766 12.6245 20.9264C12.5035 20.8762 12.3936 20.8027 12.3011 20.71L10.2911 18.7C10.1821 18.5933 10.0992 18.4629 10.0489 18.319C9.99861 18.175 9.98225 18.0213 10.0011 17.87V12H9.97111L4.21111 4.62C4.04872 4.41153 3.97544 4.14726 4.0073 3.88493C4.03915 3.6226 4.17354 3.38355 4.38111 3.22C4.57111 3.08 4.78111 3 5.00111 3H19.0011C19.2211 3 19.4311 3.08 19.6211 3.22C19.8287 3.38355 19.9631 3.6226 19.9949 3.88493C20.0268 4.14726 19.9535 4.41153 19.7911 4.62L14.0311 12H14.0011Z"
            fill="#B7B8BB"
          />
        </svg>
      </components.DropdownIndicator>
      // )
    )
  }

  return (
    <React.Fragment>
      <div className="col-12" style={{ paddingTop: "5px" }}>
        <div>
          {/* <Breadcrumbs title="Tables" breadcrumbItem="Editable Table" /> */}
          <Row>
            <Col>
              {/* <CardTitle className="deli-nondil-title">DELIVERED </CardTitle> */}
              {/* <div className="modal-header">
                  <h5>
                    DELIVERED
                  </h5>         
                </div> */}
              <Row className="mb-2">
                <Col lg={3}>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#495057",
                    }}
                  >
                    Filter By
                  </div>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col lg={3}>
                  <div>
                    <Select
                      styles={{
                        control: base => ({
                          ...base,
                          height: "40px",
                          minHeight: "40px",
                          width: "100%",
                          maxWidth: "220px",
                        }),
                        menu: base => ({
                          ...base,
                          maxWidth: "220px",
                        }),
                      }}
                      value={sortedOptions.filter(
                        option => option.value === selectedTemplate
                      )}
                      options={[
                        ...sortedOptions,
                        // { value: "all", label: "All" },
                      ]}
                      isClearable
                      placeholder="Select Template"
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator,
                      }}
                      onChange={e => {
                        if (e) {
                          setSelectedTemplate(e.value)
                          dispatch(setReportsGetSuccess(false))
                          dispatch(
                            getReports({ id: reportID, template: e.value })
                          )
                          props.setLoading(true)
                        } else {
                          setSelectedTemplate("All")
                          dispatch(setReportsGetSuccess(false))
                          dispatch(
                            getReports({ id: reportID, template: "All" })
                          )
                          props.setLoading(true)
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
              {/* <Row>
                <Col lg={6}>
                  <Card className="kpichart-style">
                    <CardBody>
                      <DeliveryKpiApexChart
                        chartData={props.kpiReports.delivery_chart_data}
                        dates={kpi_dates}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className="kpichart-style">
                    <CardBody>
                      <PriceKpiApexChart
                        chartData={props.kpiReports.pricing_chart_data}
                        dates={kpi_dates}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Card className="kpichart-style">
                    <CardBody>
                      <MtrKpiApexChart
                        chartData={props.kpiReports.mtr_chart_data}
                        dates={kpi_dates}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className="kpichart-style">
                    <CardBody>
                      <TimeKpiApexChart
                        chartData={props.kpiReports.time_chart_data}
                        dates={kpi_dates}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row> */}

              <div className="table-responsive scrollable table_shadow_div ">
                <BootstrapTable
                  keyField="id"
                  data={kpiData}
                  columns={columnskpi}
                  filter={filterFactory()}
                  cellEdit={cellEditFactory({
                    mode: "dbclick",
                    blurToSave: true,
                    // afterSaveCell: (oldValue, newValue, row, column) => {console.log(newValue);}:q!
                  })}
                />
              </div>
              <br />
            </Col>
            <Col className="position-relative ">
              <Button
                color="danger"
                paddingTop="50px"
                className="btn btn-custom md-position-absolute ms-auto p-2 bd-highlight"
                onClick={() => {
                  sendEdits()
                }}
                disabled={role === 4}
              >
                Save Edits
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default KpiTable
